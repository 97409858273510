//const appUrl = "  https://fs11admin.fantasyscore11.com"
// const appUrl = 'http://65.0.194.224:4006'

const appUrl = "https://cmsapi.fantasyscore11.com"



export const ApiConfig = {
  addSubadmin: "addSubadmin",
  getSubadmin: "getSubadmin",
  deleteSubadmin: "deleteSubadmin",
  EditSubadmin: "EditSubadmin",
  Subadminstatus: "Subadminstatus",
  addUser: "addUser",
  UserList: "UserList",
  UserStatus: "UserStatus",
  ViewUser: "ViewUser",
  ViewAdminProfile: "ViewAdminProfile",
  addCategory: "addCategory",
  deleteCategory: "deleteCategory",
  getCategorylist: "getCategorylist",
  vcatogslist: "vcatogslist",
  Setstatus: "Setstatus",
  setCatogery: "setCatogery",
  addContest: "addContest",
  ContestList: "ContestList",
  deleteContest: "deleteContest",
  SetContestStatus: "SetContestStatus",
  editContest: "editContest",
  getOfferlist: "getOfferlist",
  addbanner: "addbanner ",
  ViewBanner: "ViewBanner ",
  SetBannerStatus: "SetBannerStatus  ",
  DltBnrbyId: "DltBnrbyId",
  EditBanner: "EditBanner",
  AddNotification: "AddNotification",
  getNotificationList: "getNotificationList",
  EditNotification: "EditNotification",
  Setting: "Setting",
  ViewSetting: "ViewSetting",
  UpdateAdminPro: "UpdateAdminPro",
  AddPan: "AddPan",
  ViewPANdetails: "ViewPANdetails",
  EmailNotification: "EmailNotification",
  getEmailNotificationList: "getEmailNotificationList",
  EditEmailNoti: "EditEmailNoti",
  MobileNotification: "MobileNotification",
  getmobileNotificationList: "getmobileNotificationList",
  EditMobileNoti: "EditMobileNoti",
  Content: "Content",
  Findcontest: "Findcontent",
  ContentEdit: "ContentEdit",
  UpcmngMtchedt: "UpcmngMtchedt",
  SeriesName: "TeamDsts/SeriesName",
  MatchesSeries: "MatchesSeries",
  SeriesShrt: "SeriesShrt",
  SeriesStats: "SeriesStats",
  MatchEdit: "MatchEdit",
  MatchDate: "MatchDate",
  viewMatchesCont: "viewMatchesCont",
  ResultMtchedt: "ResultMtchedt",
  GetMatchesData: "GetliveMatchesdata",
  GetliveMatchesdata: "GetliveMatchesdata",
  UpcomingCont: "UpcomingCont",
  ContestFields: "ContestFields",
  getAdconMtch: "getAdconMtch",
  ViewPerCon: "ViewPerCon",
  PreSquadMtch: "playersdata/PreSquadMtch ",
  TeamPlayers: "playersdata/TeamPlayers",
  EditPlayers: "playersdata/EditPlayers",
  getPointstest: "PointsTBL/getPointstest",
  getPoints: "PointsTBL/getPoints",
  getPointst20: "PointsTBL/getPointst20",
  getPointsODI: "PointsTBL/getPointsODI",
  EditPoints: "PointsTBL/EditPoints",
  GetSeriesList: "Manager/GetSeriesList",
  GetTeamsList: "Manager/GetTeamsList",
  Teamdata: "Manager/Teamdata",
  Getteamplayer: "Manager/Getteamplayer",
  editplayer: "Manager/editplayer",
  changepass: "AuthRoute/changepass",
  AddBankacc: "AddBankacc",
  Viewbankdetails: "Viewbankdetails",
  verifyPan: "verifyPan",
  verifyBank: "verifyBank",
  dltbankdetails: "dltbankdetails",
  dltPandetails: "dltPandetails",
  editPANdtl: "editPANdtl",
  listPan: "listPan",
  listBank: "list",
  editbnkdtl: "editbnkdtl",
  editUser: "editUser",
  CreatedContest: "CreatedContest",
  ViewActiveCont: "ViewActiveCont",
  userCount: "userCount",
  InactvCont: "InactvCont",
  NotVerfied: "AuthRoute/NotVerfied",
  logs: "AuthRoute/logs",
  forgot: "AuthRoute/forgot",
  getTransaction: "Reports/getTransaction",
  uploads: 'moralis/upload',
  addIfps: 'moralis/uploadipfs',
  nftCreate: 'moralis/cretaeNft',
  login: 'createadm/login',
  allnftCreated: 'nft/created',
  allnftlist: 'moralis/list',
  collection: 'moralis/collection',
  createCollection: 'moralis/createCollection',
  viewReportedNfts: 'moralis/viewReportedNfts',
  reportedcollection: 'moralis/reportedcollection',
  setnftstatus: 'moralis/setnftstatus',
  inctvCollection: 'moralis/inctvCollection',
  deleteNft: 'moralis/delete',
  wallet_Manager: 'Reports/wallet_Manager',
  setBonus: 'Reports/setBonus',
  setWningamnt: 'Reports/setWningamnt',
  upload: 'upload',
  ListAadhar: 'adharlist',
  VerifyAadhar: 'verifyadhar',
  veriupi: 'veriupi ',
  upilist: 'upilist',
  dltadhar: 'dltadhar',
  voterIdStatus: "voterid/",
  DlStatus: "getdl/",
  dltdl: "dltdl",
  UserDetails: "UserDetails",
  ViewUserProfile: "ViewUser",


  // ................... //

  varfiyAdmin: `${appUrl}/createadm/`,
  varfiyAdmin2: `${appUrl}/creatdm/`,
  varfiyBank: `${appUrl}/bankdata/`,
  TeamContest: `${appUrl}/TeamDsts/`,
  baseManager: `${appUrl}/Manager/`,
  appUrl: `${appUrl}/`,
  appUrl2: `${appUrl}`,
};
